import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="322" height="12" viewBox="0 0 322 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60.0229 10.172C52.3024 9.41168 4.8806 11.3959 1.49572 11.2461C-1.50797 10.9857 0.583277 5.11242 2.91347 4.19256C3.73963 3.86562 4.81896 3.77954 7.4 3.87109C9.6637 3.95051 25.2587 3.50065 62.987 2.50341C63.7271 2.67316 65.5909 2.03666 66.0346 2.25018C66.3728 2.37404 71.8529 2.32917 79.9992 2.22534C89.1399 2.10777 114.023 1.7027 126.296 1.54433C144.408 1.30308 137.595 1.34393 158.331 1.14411C162.668 1.10137 159.282 1.47083 164.381 1.31872C175.258 0.999785 199.039 1.28377 207.27 0.99594C213.872 0.764608 215.311 0.752195 221.658 0.989123C226.735 1.17755 262.556 1.20754 266.599 0.862392C267.572 0.759242 268.313 0.811725 268.291 0.940085C268.714 0.95253 292.558 1.36514 292.664 1.47154C292.833 1.60048 293.552 1.67523 294.25 1.59359C295.52 1.45225 321.439 1.41325 321.798 1.79403C322.81 2.89149 318.417 7.37868 315.303 8.44693C312.041 9.56499 295.966 7.96977 282.169 8.50323C282.169 8.50323 278.276 8.46356 272.437 8.39567C215.417 7.77681 217.362 8.08978 206.486 8.4143C204.943 8.01878 199.121 8.9616 195.505 8.28622C194.004 8.00809 183.404 7.97608 181.033 8.24811C180.525 8.30799 176.759 8.31896 171.279 8.31916C165.82 8.31943 158.647 8.30336 151.39 8.33728C147.751 8.35421 144.09 8.3655 140.62 8.39411C137.129 8.41707 133.807 8.46846 130.866 8.521C124.962 8.62601 120.561 8.76348 119.206 8.97157C108.438 8.39185 70.3069 9.81224 60.0229 10.172Z" fill="#FFBA00" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="129" height="12" viewBox="0 0 129 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0971 10.2025C21.0295 9.45619 2.1755 11.5267 0.830264 11.383C-0.363249 11.1281 0.478719 5.25104 1.40666 4.32695C1.73566 3.99851 2.16485 3.91046 3.19067 3.99731C4.09036 4.07262 10.2903 3.59439 25.2893 2.52852C25.5832 2.69692 26.3252 2.05703 26.5012 2.26975C26.6354 2.39299 28.8139 2.33815 32.0523 2.21951C35.686 2.0853 45.578 1.63497 50.4566 1.45427C57.6568 1.18008 54.9484 1.23332 63.1914 0.995776C64.9157 0.945145 63.5689 1.32077 65.5963 1.15938C69.9203 0.820663 79.3729 1.06139 82.6455 0.758581C85.2702 0.51524 85.8422 0.500209 88.3647 0.72559C90.3826 0.904777 104.622 0.869607 106.229 0.517104C106.616 0.412183 106.91 0.463319 106.902 0.591717C107.07 0.603393 116.547 0.972619 116.589 1.07883C116.656 1.20746 116.942 1.28091 117.219 1.19799C117.725 1.05434 128.028 0.968196 128.169 1.34832C128.57 2.44393 126.815 6.93909 125.576 8.013C124.277 9.13698 117.89 7.57101 112.405 8.12957C112.405 8.12957 110.857 8.09698 108.536 8.03972C85.8715 7.52459 86.6443 7.83403 82.3203 8.17833C81.7075 7.78562 79.3918 8.73902 77.9556 8.07023C77.3593 7.79482 73.1457 7.7821 72.2029 8.05844C72.0008 8.11925 70.5037 8.13707 68.3254 8.14724C66.1555 8.15744 63.3043 8.15441 60.4194 8.20154C58.9728 8.22509 57.5177 8.24303 56.1383 8.27795C54.7505 8.30727 53.4299 8.3647 52.2606 8.42259C49.9138 8.53834 48.164 8.68382 47.6251 8.89437C43.3458 8.33425 28.1857 9.82399 24.0971 10.2025Z" fill="#FFBA00" />
    </svg>
  </span>
)
